// extracted by mini-css-extract-plugin
export var pwaBenefitWebsiteSection = "s_cr";
export var pwaCardRowIconsSection = "s_cv";
export var pwaCycleDevelopmentSection = "s_cs";
export var pwaCycleDevelopmentSection__testimonial = "s_ct";
export var pwaDomainExpertiseSection = "s_cy";
export var pwaFeaturedCaseSection = "s_cz";
export var pwaProjLogoSection = "s_cF";
export var pwaServiceAchievementsSection = "s_cG";
export var pwaServiceClientsSection = "s_cH";
export var pwaServicesCompaniesSection = "s_cw";
export var pwaServicesCycleDevelopmentSection = "s_cx";
export var pwaSuccessStoriesSection = "s_cC";
export var pwaTechStackSection = "s_cB";
export var pwaWhyChooseSection = "s_cD";